export const BASE_API = process.env.VUE_APP_BASE_API;
export const APP_URL = process.env.VUE_APP_APP_URL;

export const API_ROUTES = {
  AUTH: {
    VERIFICATION: {
      PHONE: '/auth/verify/phone/initiate',
      PHONE_COMPLETE: '/auth/verify/phone/complete',
    }
  }
}

export const AUTH_ROUTES = {
  SIGNUP: '/signup',
  LOGIN: '/login',
  SETUP: '/setup/complete',
  FORGOT_PASSWORD: '/password/forgot',
  RESET_PASSWORD: '/password/reset/:userId/:token',
}

export const VERIFICATION_ROUTES = {
  PHONE: '/verification/phone',
  EMAIL: '/verification/email/success'
}

export const ONBOARDING_ROUTES = {
  BASE: '/onboarding',
  WORKING: '/onboarding/working',
  EXPERIENCE: '/onboarding/experience',
  ORDINARY: '/onboarding/ordinary',
  MAPS: '/onboarding/maps',
  ENERGETIC: '/onboarding/energetic',
  ATTITUDE: '/onboarding/attitude',
  RESIDENCE: '/onboarding/residence',
  CURRENT_RESIDENCE: '/onboarding/current-residence',
  INDUSTRY: '/onboarding/industry',
}

export const GET_LOAN_ROUTES = {
  AMOUNT: '/loan/amount',
  PERSON: '/loan/person',
  PURPOSE: '/loan/purpose',
  EXISTING_LOAN: '/loan/existing-loan',
  INCOME: '/loan/income',
  EMAIL: '/loan/email',
  PHONE: '/loan/phone',
  EMPLOYERS: '/loan/employers',
  MONTHLY_SALES: '/loan/monthly-sales',
}

export const SEARCH_ROUTES = {
  SEARCH_RESULT: '/search'
}

export const DASHBOARD_ROUTES = {
  OVERVIEW: '/',
  HISTORY: '/history',
  SETTINGS: {
    PERSONAL: '/settings/personal',
    PROFESSIONAL: '/settings/professional',
    DOCUMENTS: '/settings/documents',
    ADVANCED: '/settings/advanced'
  },
  HELP: '/help'
}
