import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/tailwind.css";
import "./assets/scss/style.scss";
import OtpInput from "@bachdgvn/vue-otp-input";
import VueHead from "vue-head";
import Notie from "notie";
import "notie/dist/notie.min.css";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import mixpanel from 'mixpanel-browser';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://fc654056798e46289998d35bddcd822c@o576375.ingest.sentry.io/5729926",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
	logErrors: true,
});

Bugsnag.start({
	apiKey: "800537d687bacc8b885d37470eb1d0df",
	plugins: [new BugsnagPluginVue()],
	enabledReleaseStages: [ 'production', 'staging' ],
});

const bugsnagVue = Bugsnag.getPlugin("vue");
bugsnagVue.installVueErrorHandler(Vue);

Vue.component("v-otp-input", OtpInput);

Vue.config.productionTip = false;

Vue.use(VueHead);
mixpanel.init('61fed93f4a22b2676a3946cd1418ed4a');


Vue.prototype.$notie = Notie;
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");

