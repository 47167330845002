import {
	AUTH_ROUTES,
	ONBOARDING_ROUTES,
	GET_LOAN_ROUTES,
	SEARCH_ROUTES,
	VERIFICATION_ROUTES,
	DASHBOARD_ROUTES
} from "../constants";
import { redirectIfAuthenticated } from "../middlewares";

function loadView(view) {
	return () => import(/* webpackChunkName: "about" */ `../views/${view}`);
}

export const Routes = [
	{
		path: AUTH_ROUTES.SIGNUP,
		name: "Signup",
		component: loadView("auth/signup.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
	{
		path: AUTH_ROUTES.LOGIN,
		name: "Login",
		component: loadView("auth/login.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
	{
		path: AUTH_ROUTES.SETUP,
		name: "Setup",
		component: loadView("auth/setup-complete.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: VERIFICATION_ROUTES.PHONE,
		name: "Verify Phone Number",
		component: loadView("verification/phone.vue"),
		meta: {
			// requiresAuth: true,
			prevent: ['phoneNumberVerified'],
		}
	},
	{
		path: VERIFICATION_ROUTES.EMAIL,
		name: "Verify Email Address",
		component: loadView("verification/verified-email.vue"),
	},
	{
		path: AUTH_ROUTES.FORGOT_PASSWORD,
		name: "Forgot Password",
		component: loadView("auth/forgot-password.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
	{
		path: AUTH_ROUTES.RESET_PASSWORD,
		name: "Reset Password",
		component: loadView("auth/reset-password.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
	{
		path: ONBOARDING_ROUTES.BASE,
		name: "Onboarding",
		component: loadView("Onboarding.vue"),
		// meta: {
		// 	requiresAuth: true,
		// 	authScope: ['phoneNumberVerified'],
		// }
	},
	{
		path: GET_LOAN_ROUTES.PERSON,
		name: "Get Loan Person",
		component: loadView("get-loan/person.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: GET_LOAN_ROUTES.PURPOSE,
		name: "Get Loan Purpose",
		component: loadView("get-loan/purpose.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: GET_LOAN_ROUTES.EXISTING_LOAN,
		name: "Get Loan Existing",
		component: loadView("get-loan/existing-loan.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: GET_LOAN_ROUTES.INCOME,
		name: "Get Loan Income",
		component: loadView("get-loan/income.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: GET_LOAN_ROUTES.EMAIL,
		name: "Get Loan Email",
		component: loadView("get-loan/email.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: GET_LOAN_ROUTES.PHONE,
		name: "Get Loan Phone",
		component: loadView("get-loan/phone.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: GET_LOAN_ROUTES.EMPLOYERS,
		name: "Get Loan Employers",
		component: loadView("get-loan/employers.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: GET_LOAN_ROUTES.MONTHLY_SALES,
		name: "Get Loan Sales",
		component: loadView("get-loan/monthly-sales.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: SEARCH_ROUTES.SEARCH_RESULT,
		name: "Search Results",
		component: loadView("Search.vue"),
		meta: {
			checkProfile: true,
			requiresAuth: 'guest',
			authScope: ['updatedProfile'],
		}
	},
	{
		path: DASHBOARD_ROUTES.OVERVIEW,
		name: "Dashboard Overview",
		component: loadView("Home.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified', 'updatedProfile'],
		}
	},
	{
		path: DASHBOARD_ROUTES.HISTORY,
		name: "Dashboard History",
		component: loadView("dashboard/History.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified', 'updatedProfile'],
		}
	},
	{
		path: DASHBOARD_ROUTES.SETTINGS.PERSONAL,
		name: "Personal Settings",
		component: loadView("settings/Personal.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: DASHBOARD_ROUTES.SETTINGS.PROFESSIONAL,
		name: "Professional Settings",
		component: loadView("settings/Professional.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: DASHBOARD_ROUTES.SETTINGS.DOCUMENTS,
		name: "Documents Settings",
		component: loadView("settings/Documents.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: DASHBOARD_ROUTES.SETTINGS.ADVANCED,
		name: "Dashboard Settings",
		component: loadView("settings/Advanced.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
];
