import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";
import { initiatePhoneNumberVerification } from "../utils/phone";
import { Routes } from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: Routes,
});

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	if (requiresAuth) {
		try {
			const response = await Store.dispatch("userProfile");
			if (!response.phoneNumberVerified) {
				if (to.meta.authScope && to.meta.authScope.includes('phoneNumberVerified')) {
					const res = await initiatePhoneNumberVerification(response.user.id);
					if (res.status === 'success') {
						Store.commit('SET_PHONE_VERIFICATION_TTL', res.ttl);
						return next({ path: '/verification/phone', query: { redirect: to.fullPath }, });
					}
				}
			}
			if (!response.emailVerified) {
				if (to.meta.authScope && to.meta.authScope.includes('emailVerified')) {
					return next('/verification/email');
				}
			}
			if (!response.updatedProfile) {
				if (to.meta.authScope && to.meta.authScope.includes('updatedProfile')) {
					return next('/setup/complete');
				}
			}
			return next();
		} catch (error) {
			if (error?.statusCode === 401) {
				return next({
					path: "/login",
					query: { redirect: to.fullPath },
				});
			}
			return next();
		}
	} else {
		next();
	}
});

export default router;
