import axios from "axios";

const AUTH_TOKEN = null;

const APP_URL = process.env.VUE_APP_BASE_API;

// const DEBUG = process.env.NODE_ENV === "development";
// const instance = axios.create({
// 	baseURL: APP_URL,
// 	...(AUTH_TOKEN && {
// 		headers: {
// 			Authorization: `Bearer ${AUTH_TOKEN}`,
// 		},
// 	}),
// });

class API {
	constructor(url = undefined, headers = {}) {
		this.instance = axios.create({
			baseURL: url || APP_URL,
			headers: {
				...(AUTH_TOKEN && {
					Authorization: `Bearer ${AUTH_TOKEN}`,
				}),
				...headers,
			},
		});
	}

	modifyHeaders(key, value) {
		this.instance.interceptors.request.use(function(config) {
			config.headers[key] = value;
			return config;
		});
	}

	async asyncWrapper(request, ...props) {
		try {
			const { data } = await request(...props);
			return { status: true, data };
		} catch (error) {
			return { status: false, error: error.response?.data };
		}
	}

	async get(path, headers = {}) {
		return await this.asyncWrapper(this.instance.get, path, headers);
	}

	async post(path, payload = {}, headers = {}) {
		return await this.asyncWrapper(this.instance.post, path, payload, headers);
	}
}

export const ApiInstance = new API(null, {});
