import Vue from "vue";
import Vuex from "vuex";
import { ApiInstance as api } from "../utils";
import mixpanel from 'mixpanel-browser';
import Cookies from 'js-cookie';

Vue.use(Vuex);

const redirectPaths = {
	phoneNumberVerified: "/verification/phone",
	// filledPsychometric: "/onboarding",
	emailVerified: "/verification/phone",
};

export default new Vuex.Store({
	state: {
		status: "",
		token: localStorage.getItem("auth-token") || "",
		profile: {
			loaded: false,
		},
		ttl: 300,
	},
	mutations: {
		LOGIN_SUCCESS(state, token) {
			state.token = token;
		},
		SET_USER_PROFILE(state, data) {
			state.profile = data;
		},
		SET_PHONE_VERIFICATION_TTL(state, data) {
			state.ttl = data;
		},
	},
	actions: {
		async loginUser(ctx, data) {
			return new Promise((resolve, reject) => {
				api
					.post("/auth/login", data)
					.then((response) => {
						if (response.status && response.data?.statusCode === 200) {
							mixpanel.identify(data.email, {
								'distinct_id': Cookies.get('mix_id')
							});
							mixpanel.track('Login successful', {
								'distinct_id': Cookies.get('mix_id')
							});
							const {
								phoneNumberVerified,
								// filledPsychometric, 
								emailVerified,
								token,
							} = response.data.data;

							ctx.commit("LOGIN_SUCCESS", token);
							api.modifyHeaders("Authorization", "Bearer " + token);
							localStorage.setItem("auth-token", token);

							if (!phoneNumberVerified)
								return resolve({
									status: true,
									pending: redirectPaths["phoneNumberVerified"],
								});
							// if (!filledPsychometric)
							// 	return resolve({
							// 		status: true,
							// 		pending: redirectPaths["filledPsychometric"],
							// 	});
							if (!emailVerified)
								return resolve({
									status: true,
									pending: redirectPaths["emailVerified"],
								});
							return resolve({ status: true });
						} else {
							return reject(response.error);
						}
					})
					.catch((error) => reject(error));
			});
		},
		async userProfile(ctx, data) {
			await api.modifyHeaders(
				"Authorization",
				"Bearer " + localStorage.getItem("auth-token")
			);
			await api.modifyHeaders(
				"Access-Control-Allow-Origin",
				"*"
			);
			return new Promise((resolve, reject) => {
				api
					.get("/user/profile", data)
					.then((response) => {
						if(response.data?.data?.updatedProfile) {
							if(window.location.pathname === '/setup/complete')
							return window.location.replace('/');
						}
						if (response.status && response.data?.statusCode === 200) {
							ctx.commit("SET_USER_PROFILE", {
								...response.data.data,
								loaded: true,
							});
							return resolve(response.data.data);
						} else {
							if (!response.status && response.error?.statusCode === 401) {
								ctx.commit("SET_USER_PROFILE", {
									loaded: true,
								});
							}
							return reject(response.error);
						}
					})
					.catch((error) => {
						if (error?.response?.status === 401) {
							ctx.commit("SET_USER_PROFILE", {
								loaded: true,
							});
						}
						return reject(error);
					});
			});
		},
	},
	getters: {
		authToken: (state) => state.token,
		ttl: (state) => state.ttl,
	},
});
