import { API_ROUTES } from "../constants";
import { ApiInstance as api } from "./api";

export async function initiatePhoneNumberVerification(userId) {
	try {
		const response = await api.get(API_ROUTES.AUTH.VERIFICATION.PHONE + '/' + userId);
		return response.data;
	} catch (error) {
		return { status: false }
	}
}
